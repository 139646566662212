<template>
  <div id="suivi-heures">
    <!-- <header class="jumbotron">
      <h3>{{content}}</h3>
    </header> -->
    

    <section class="modal-body">
      <div class="flex-container">
        <div class="flex-item-left">
          <label for="month">
            Choisir une période&nbsp;
          </label>
        </div>
        <div class="flex-item-right">
          <input
            id="month"
            type="month"
            name="month"
            @change="update()"
            v-model="date"
          />
        </div>
        <div class="flex-item-end">
          <span v-show="loading" class="spinner-border spinner-border-sm"></span>
        </div>
      </div>
    </section>
    <section v-if="message">
      <div>
        {{ message }}
      </div>
    </section>

      <section class="fiche-analytique" v-if="!messageFicheAnalytique">
        <h4>Fiche analytique</h4>
        <table class="flex-table">
          <thead>
            <tr>
              <th class="box1">&nbsp;</th>
              <th class="box2">Total</th>
              <th
                class="box3"
                v-for="item in headerItems.items"
                v-bind:key="item"
              >
                {{ item }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in items"
              v-bind:class="{
                colorItem: item.libelle === item.groupeLib,
                colorTotG: item.libelle === 'Total Général',
              }"
            >
              <td data-label="Libellé" class="box1">
                {{ item.libelle }}
              </td>
              <td data-label="Total" class="box2">
                {{ item.total }}
              </td>
              <td
                data-label=""
                class="box3"
                v-for="value in item.items"
              >
                {{ value }}
              </td>
            </tr>
          </tbody>
        </table>
      </section>
      <section v-else>
        <p>{{ messageFicheAnalytique }}</p>
      </section>
      <br />
       <section class="decompte-heures" v-if="!messageDecompteHeures">
         <h4>Décompte heures</h4>
        <table class="flex-table">
          <thead>
            <tr>
              <th class="box1">&nbsp;</th>
              <th class="box2">Total</th>
              <th
                class="box3"
                v-for="item in headerItemsDecompte.items"
                v-bind:key="item"
              >
                {{ item }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in itemsDecompte"
              v-bind:class="{
                colorTotG: item.libelle === 'Total'
              }"
            >
              <td data-label="Libellé" class="box1">
                {{ item.libelle }}
              </td>
              <td data-label="Total" class="box2">
                {{ item.total }}
              </td>
              <td
                data-label=""
                class="box3"
                v-for="value in item.items"
              >
                {{ value }}
              </td>
            </tr>
          </tbody>
        </table>
      </section>
      <section v-else>
        <p>{{ messageDecompteHeures }}</p>
      </section>
    </div>
</template>

<script>
import UserService from "../services/user.service";

export default {
  name: "decompte",
  data() {
    return {
      content: "",
      loading: false,
      message: "",
      headerItems: {},
      items: [],
      headerItemsDecompte: {},
      itemsDecompte: [],
      date: new Date().toISOString().slice(0, 7),
      messageFicheAnalytique: "",
      messageDecompteHeures: "",
    };
  },
  mounted() {
    this.getFicheAnalytique();
    this.getDecompteHeures();
  },
  methods: {
    update() {
      if (!this.date)
        return;
      this.getFicheAnalytique();
      this.getDecompteHeures();
    },
    getFicheAnalytique() {
      this.loading = true;
      let params = new Object();
      params.date = this.date;
      UserService.getFicheAnalytique(params).then(
        (response) => {
          this.headerItems = {};
          this.items = [];
          if (response.data.length) {
            for (let i = 0; i < response.data.length; i++) {
              if (i === 0) {
                this.headerItems = response.data[i];
              } else {
                this.items.push(response.data[i]);
              }
            }
            this.messageFicheAnalytique = "";
          } else {
            this.messageFicheAnalytique = "Pas de résultat \"Fiche Analytique\"";
          }
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          this.messageFicheAnalytique = "Cette page rencontre des difficultés techniques";
          // this.message =
          //   (error.response && error.response.data) ||
          //   error.message ||
          //   error.toString();
        }
      );
    },
    getDecompteHeures() {
      this.loading = true;
      let params = new Object();
      params.date = this.date;
      UserService.getDecompteHeures(params).then(
        (response) => {
          this.headerItemsDecompte = {};
          this.itemsDecompte = [];
          if (response.data.length) {
            for (let i = 0; i < response.data.length; i++) {
              if (i === 0) {
                this.headerItemsDecompte = response.data[i];
              } else {
                this.itemsDecompte.push(response.data[i]);
              }
            }
            this.messageDecompteHeures = "";
          } else {
            this.messageDecompteHeures = "Pas de résultat \"Décompte Heures\"";
          }
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          this.messageDecompteHeures = "Cette page rencontre des difficultés techniques";
          // this.message =
          //   (error.response && error.response.data) ||
          //   error.message ||
          //   error.toString();
        }
      );
    },
  },
};
</script>

<style>
/*** FLEX TABLE */
#suivi-heures .flex-table {
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  background: #fff;
  font-size: 0.9em;
}
#suivi-heures .flex-table thead {
  color: #fff;
  background: #68b5c7;
}
#suivi-heures .flex-table thead tr,
#suivi-heures .flex-table tbody tr {
  display: flex;
}
#suivi-heures .flex-table tbody tr + tr {
  border-top: 1px solid #ccc;
}
#suivi-heures .flex-table thead tr th,
#suivi-heures .flex-table tbody tr td {
  display: flex;
  flex: 1;
  padding: 0.1em;

}
#suivi-heures .flex-table thead tr th.box1 {
  min-width: 160px;
}
#suivi-heures .flex-table thead tr th.box2 {
  min-width: 40px;
  justify-content: center;
}
#suivi-heures .flex-table thead tr th.box3 {
  min-width: 20px;
  justify-content: center;
  text-align: center;
}
#suivi-heures .flex-table tbody tr td.box1 {
  min-width: 160px;
}
#suivi-heures .flex-table tbody tr td.box2 {
  min-width: 40px;
  justify-content: center;
}
#suivi-heures .flex-table tbody tr td.box3 {
  min-width: 20px;
  justify-content: center;
}
#suivi-heures .flex-table tbody tr td > span {
  width: 100%;
}
@media screen and (max-width: 1140px) {
 #suivi-heures .flex-table {
    font-size: 0.75em;
  }
  #suivi-heures .flex-table tbody tr td.box3 {
  border-left: 1px solid #ccc;

}
}
@media screen and (max-width: 960px) {
  #suivi-heures .flex-table {
    font-size: 0.6em;
  }

  #suivi-heures .flex-table thead tr th.box1 {
  min-width: 60px;
}
#suivi-heures .flex-table thead tr th.box2 {
  min-width: 20x;

}
#suivi-heures .flex-table thead tr th.box3 {
  min-width: 5px;

}
#suivi-heures .flex-table tbody tr td.box1 {
  min-width: 60px;
}
#suivi-heures .flex-table tbody tr td.box2 {
  min-width: 20px;

}
#suivi-heures .flex-table tbody tr td.box3 {
  min-width: 5px;
  border-left: 1px solid #ccc;

}

}

.colorItem {
  background-color: orange !important;
  font-weight: bold !important;
}
.colorTotG {
  background-color: orangered !important;
  font-weight: bold !important;
}
</style>
